import { convertToViName } from "@/utils/stringUtils";

const VI_TOKEN = "vi_token";

// export const getViUserName = () => {
//   const userName = "9bc0a717-6e1f-45fd-9056-04de5d148c3f"; // todo: get from API
//   return userName ? convertToViName(userName) : userName;
// };

export const getViToken = () =>
  localStorage.getItem(VI_TOKEN);

export const setViToken = (value: string) =>
  localStorage.setItem(VI_TOKEN, value);
