import React, { useEffect, useState } from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import TimerFixed from "@components/common/timerFixed";

import useCountdownTimestampProxy from "../hooks/useCountdownTimestampProxy";
import { calculateTimerValue } from "../utils";

import { TextChatTimerProps } from "./types";
import { checkIfSessionEndAfterFiveMinutes, checkIfSessionEndAfterThreeMinutes } from "./utils";
import "./styles.scss";

const TextChatDuration = ({
  timestamp = new Date().getTime(),
  sessionEndsSoonCallback,
}: TextChatTimerProps) => {
  const { proxiedCountdownTimestamp, setTimestamp } = useCountdownTimestampProxy();
  const [isSessionEndsSoon, setIsSessionEndsSoon] = useState(
    checkIfSessionEndAfterFiveMinutes(calculateTimerValue(proxiedCountdownTimestamp ?? 0)),
  );
  useEffect(() => {
    setTimestamp(timestamp ?? 0);
  }, [setTimestamp, timestamp]);

  return (
    <div
      className={`text-chat__duration    
        ${isSessionEndsSoon ? " text-chat__duration--warning-fiv-minutes" : ""}
        ${checkIfSessionEndAfterThreeMinutes(calculateTimerValue(proxiedCountdownTimestamp ?? 0)) ? " text-chat__duration--warning-three-minutes" : ""}
      `}
    >
      <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size28} />
      <TimerFixed
        value={calculateTimerValue(proxiedCountdownTimestamp ?? 0)}
        valueCallback={(value) => {
          setIsSessionEndsSoon(checkIfSessionEndAfterFiveMinutes(value));
          if (sessionEndsSoonCallback) {
            sessionEndsSoonCallback(checkIfSessionEndAfterFiveMinutes(value));
          }
        }}
      />
    </div>
  );
};
export default TextChatDuration;
