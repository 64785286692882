import React from "react";

import TextChatContainer from "@/components/textChat/textChatContainer";
import { isBrowser } from "@/utils/env";

const DirectChatPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return <TextChatContainer />;
};

export default DirectChatPage;
