import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import TextChat from "@/components/textChat";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";

import { AWAITING_FOR_CONNECTION_TIMER_PERIOD_MS } from "./constants";
import { getSessionIdFromUrl } from "./utils";

import type { endSession } from "../../chat/graphql/__generated__/endSession";
import type {
  getSession,
  getSessionVariables,
  getSession_getSession_client,
  getSession_getSession_expert,
} from "../../chat/graphql/__generated__/getSession";
import { END_SESSION } from "../../chat/graphql/END_SESSION";
import { GET_SESSION } from "../../chat/graphql/GET_SESSION";

const TextChatContainer = () => {
  const sessionId = getSessionIdFromUrl();
  const [isSessionInProgress, setIsSessionInProgress] = useState(false);
  const [VIName, setVIName] = useState<string>();
  const [receiver, setReceiver] = useState<getSession_getSession_expert | getSession_getSession_client>();
  const [isCurrentUserExpert, setIsCurrentUserExpert] = useState<boolean>();
  const getSessionTimerRef = useRef<NodeJS.Timer | null>(null);
  const { isUserLoggedIn } = useContext(UserContext);
  const {
    setIsExpert,
    setIsAlertShown,
    isGetActiveSessionLoopRunning,
    setIsGetActiveSessionLoopRunning,
  } = useContext(GlobalContext);

  const [
    getSession,
    {
      loading: getSessionLoading,
      error: getSessionError,
      data: getSessionData,
      refetch: getSessionRefetch,
    },
  ] = useLazyQuery<getSession, getSessionVariables>(GET_SESSION, { fetchPolicy: "no-cache" });

  const [endSession, {
    loading: endSessionIsLoading,
    error: endSessionError,
    data: endSessionResponse,
  }] = useMutation<endSession>(END_SESSION, { fetchPolicy: "network-only" });

  const endSessionCallback = useCallback(() => {
    endSession({ variables: { sessionID: sessionId } });
  }, [endSession, sessionId]);

  const stopGetSessionTimer = useCallback(() => {
    if (getSessionTimerRef.current !== null) {
      clearInterval(getSessionTimerRef.current);
      getSessionTimerRef.current = null;
    }
  }, []);

  const restartGetSessionTimer = useCallback((periodMs: number) => {
    stopGetSessionTimer();
    getSession({ variables: { sessionID: parseInt(sessionId || "0", 10) } });
    getSessionTimerRef.current = setInterval(
      () => {
        getSession({ variables: { sessionID: parseInt(sessionId || "0", 10) } });
      },
      periodMs,
    );
  }, [getSession, sessionId, stopGetSessionTimer]);

  // Sets getSession timer
  useEffect(() => {
    if (isUserLoggedIn) {
      restartGetSessionTimer(AWAITING_FOR_CONNECTION_TIMER_PERIOD_MS);
    } else {
      setAuthParamToURL(window.location, FormTypeEnum.Login);
    }
    return () => {
      stopGetSessionTimer();
    };
  }, [isUserLoggedIn, restartGetSessionTimer, stopGetSessionTimer]);

  // Turning off getActiveSession query while session is running
  useEffect(() => {
    setIsGetActiveSessionLoopRunning(false);
    return () => {
      setIsGetActiveSessionLoopRunning(isUserLoggedIn);
    };
  }, [
    isUserLoggedIn,
    isGetActiveSessionLoopRunning, // Dependency for page refresh case
    setIsGetActiveSessionLoopRunning,
  ]);

  // getSession response handler
  useEffect(() => {
    if (
      getSessionData && !getSessionLoading && !getSessionError) {
      const {
        client,
        expert,
        status,
        statistics: {
          durationInMinutes,
          totalPrice,
        },
      } = getSessionData.getSession;
      setIsCurrentUserExpert(getSessionData.getMyProfile.isExpert);
      setIsExpert(getSessionData.getMyProfile.isExpert);
      if ([SessionStatus.waiting, SessionStatus.in_progress].includes(status)) {
        if (!getSessionData.getMyProfile.isExpert) {
          setVIName(client.voxImplantInfo.userName);
          if (!receiver) {
            setReceiver(expert);
          }
        } else {
          setVIName(expert.voxImplantInfo.userName);
          if (!receiver) {
            setReceiver(client);
          }
        }
        if (status === SessionStatus.in_progress) {
          setIsSessionInProgress(true);
        }
      } else {
        // todo: probably move review page to root URL
        navigate("/chat/review", {
          state: {
            receiver,
            durationInMinutes,
            totalPrice,
            sessionID: sessionId,
            sessionType: SessionType.CHAT,
            isExpert: getSessionData.getMyProfile.isExpert || false,
          },
        });
      }
    }
  }, [getSessionLoading, getSessionError, getSessionData, receiver, sessionId, setIsExpert]);

  // End session success handler
  useEffect(() => {
    if (endSessionResponse && !endSessionIsLoading && !endSessionError) {
      const {
        durationInMinutes,
        totalPrice,
      } = endSessionResponse.endSession;

      navigate("/chat/review", {
        state: {
          receiver,
          durationInMinutes,
          totalPrice,
          sessionID: sessionId,
          sessionType: SessionType.CHAT,
          isExpert: isCurrentUserExpert || false,
        },
      });
    }
    // todo: check how affects
  }, [endSessionIsLoading, endSessionError, endSessionResponse, receiver, isCurrentUserExpert, sessionId]);

  // Turning off the alert
  useEffect(() => {
    setIsAlertShown(false);

    return () => {
      setIsAlertShown(true);
    };
  }, [setIsAlertShown]);

  const {
    outOfMoneyTime,
    voxConversationID,
  } = getSessionData?.getSession || {};

  return (
    <>
      <Helmet>
        <html className="html--full-height overflow--hidden" lang="ru" />
        <body className="footer--hide header--hide body--full-height overflow--hidden carrot--disable" />
      </Helmet>
      {VIName && receiver && (
        <TextChat
          sessionId={sessionId}
          conversationUuid={voxConversationID}
          viName1={VIName}
          receiver={receiver}
          outOfMoneyTime={outOfMoneyTime}
          isSessionInProgress={isSessionInProgress}
          setIsSessionInProgress={setIsSessionInProgress}
          endSessionCallback={endSessionCallback}
          getSessionRefetch={getSessionRefetch}
        />
      )}
    </>
  );
};

export default TextChatContainer;
