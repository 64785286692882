import React from "react";

import { Button, ButtonIconPositionEnum, ButtonSizeEnum } from "@/components/common/button";
import FastPayment from "@/components/common/fastPayment";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { getActiveSession_getActiveSession_expert } from "@/components/layout/sessionController/graphql/__generated__/getActiveSession";
import { declenateWord } from "@/utils/stringUtils";

import { TextChatFooterProps } from "./types";
import "./styles.scss";

const TextChatFooter = ({
  unreadMessageCount,
  setUnreadMessageCount,
  scrollFeedToBottom,
  isExpert,
  isSessionInProgress,
  isSessionEndsSoon,
  receiver,
  getSessionRefetch,
  isShowGiftsBlock,
  setIsShowGiftsBlock,
  sessionId,
  message,
  sendMessage,
  onChange,
  onMessageInputKeyDown,
  textareaRef,
}: TextChatFooterProps) =>
  (
    <div className="text-chat-footer__bottom">
      {!!unreadMessageCount && (
      <div
        onClick={() => {
          setUnreadMessageCount(0);
          scrollFeedToBottom();
        }}
        className="text-chat__scroll-block"
      >
        <Icon type={IconTypeEnum.Left} size={IconSizeEnum.Size20} />
        <div>
          {`${unreadMessageCount} ${declenateWord(unreadMessageCount, ["новое сообщение", "новых сообщения", "новых сообщений"])}`}
        </div>
      </div>
      )}
      {!isExpert && isSessionInProgress && (
      <FastPayment
        isSessionEndsSoon={isSessionEndsSoon}
        expertRate={(receiver as getActiveSession_getActiveSession_expert).rates.chat}
        sessionRefetch={getSessionRefetch}
        isShowGiftsBlock={isShowGiftsBlock}
        setIsShowGiftsBlock={setIsShowGiftsBlock}
        sessionId={sessionId}
      />
      )}
      <div className="text-chat-footer__bottom-content">
        <div className="text-chat-footer__input-controls">
          <Button
            disabled={!message}
            text=""
            icon={IconTypeEnum.Send}
            iconPosition={ButtonIconPositionEnum.Left}
            onClick={sendMessage}
            size={ButtonSizeEnum.Middle}
            className="text-chat__send"
          />
          <textarea
            placeholder="Сообщение..."
            value={message}
            onChange={onChange}
            onKeyDown={onMessageInputKeyDown}
            ref={textareaRef}
            className="text-chat__input ym-disable-keys sentry-mask" // Hides message from Webvisor and Sentry for safety
          />
        </div>
      </div>
    </div>
  );

export default TextChatFooter;
