import * as VoxImplant from "voximplant-websdk";
import { Call } from "voximplant-websdk/Call/Call";
import { Client } from "voximplant-websdk/Client";

// todo: move to config
const application_name = process.env.GATSBY_VI_APPLICATION_NAME;
const account_name = process.env.GATSBY_VI_ACCOUNT_NAME;

type GetKeyCallback = {
  (key: string): void;
};

let voxAPI: Client | null = null;
let URI: string | null = null;
let getKeyCallback: GetKeyCallback | null = null;
let currentCall: Call | null = null;

//= ==================Init===================

/**
 * Initiates VI singletone for client and binds necessary events
 * @param callback Function that takes the key and calculates VI access token on backend
 */
export function init(application_user: string, callback: GetKeyCallback) {
  URI = `${application_user}@${application_name}.${account_name}.voximplant.com`;
  getKeyCallback = callback;
  initVI();
}

function initVI() {
  // Create VoxImplant instance
  voxAPI = VoxImplant.getInstance();

  // Add event listeners
  voxAPI.addEventListener(VoxImplant.Events.SDKReady, onSdkReady);
  voxAPI.addEventListener(
    VoxImplant.Events.ConnectionEstablished,
    onConnectionEstablished,
  );
  voxAPI.addEventListener(
    VoxImplant.Events.ConnectionFailed,
    onConnectionFailed,
  );
  voxAPI.addEventListener(
    VoxImplant.Events.ConnectionClosed,
    onConnectionClosed,
  );
  voxAPI.addEventListener(VoxImplant.Events.AuthResult, onAuthResult);
  voxAPI.addEventListener(VoxImplant.Events.IncomingCall, onIncomingCall);
  voxAPI.addEventListener(VoxImplant.Events.MicAccessResult, onMicAccessResult);

  // Initialize the SDK
  try {
    voxAPI.init({
      showDebugInfo: false,
    });
  } catch (e) {
    // showing the message if browser doesn't support WebRTC
    if (e.message == "NO_WEBRTC_SUPPORT") alert("WebRTC support isn't available");
  }
}

// Now we can use SDK functions - establish connection with VoxImplant
function onSdkReady() {
  console.log("onSdkReady");
  voxAPI.connect(); // mic/cam access dialog will be shown after the function call
}

// Process mic/cam dialog input result
function onMicAccessResult(e) {
  console.log("onMicAccessResult", e);
  if (e.result) {
    // access was allowed
  } else {
    // access was denined - no connection will happen
  }
}

// Establishing connection with VoxImplant
function onConnectionEstablished() {
  console.log("onConnectionEstablished");

  voxAPI.requestOneTimeLoginKey(URI);

  // const urlParams = new URLSearchParams(window.location.search);
  // const application_user_password = urlParams.get("password");
  // voxAPI.login(URI, application_user_password);
}

// Couldn't establish connection with VoxImplant
function onConnectionFailed() {
  console.log("onConnectionFailed");
  // Websockets or UDP connection is unavailable
}

// Connection with VoxImplant was closed
function onConnectionClosed() {
  console.log("onConnectionClosed");
  // Can call connect here to reconnect
}

function onAuthResult(e) {
  console.log("onAuthResult", e);
  if (e.result) {
    // authorization was successful - can make/receive calls now
    console.log("authorization was successful - can make/receive calls now");
  } else if (e.code == 302) {
    console.log(e.key);
    // authorization by token. We got a key
    // IMPORTANT: You should always calculate the token by key on your backend
    getKeyCallback(e.key);
  } else {
    // authorization failed - check e.code to see the error code
  }
}

export function loginWithToken(token) {
  return voxAPI.loginWithOneTimeKey(URI, token);
}

export function getClientState() {
  return voxAPI?.getClientState();
}

export function disconnect() {
  return voxAPI?.disconnect();
}

// handle incoming call
function onIncomingCall(e) {
  console.log("onIncomingCall", e);
  currentCall = e.call;
  // add event listeners
  currentCall.addEventListener(
    VoxImplant.CallEvents.Connected,
    onCallConnected,
  );
  currentCall.addEventListener(
    VoxImplant.CallEvents.Disconnected,
    onCallDisconnected,
  );
  currentCall.addEventListener(VoxImplant.CallEvents.Failed, onCallFailed);
  // Answer automatically. It's better to show the dialog to let answer/reject the call in real app.
  currentCall.answer("", {}, { receiveVideo: true, sendVideo: true });
}

//= ==================Call===================

/**
 * Makes outbound call
 * @param userName VI name of user to call
 * @returns
 */
export function createCall(userName) {
  console.log("createCall");
  currentCall = voxAPI.call(userName, { receiveVideo: true, sendVideo: true });
  // add event listeners
  currentCall.addEventListener(
    VoxImplant.CallEvents.Connected,
    onCallConnected,
  );
  currentCall.addEventListener(
    VoxImplant.CallEvents.Disconnected,
    onCallDisconnected,
  );
  currentCall.addEventListener(VoxImplant.CallEvents.Failed, onCallFailed);
}

// Call connected handler for both caller and receiver
function onCallConnected(e) {
  console.log("onCallConnected", e);

  // Start sending video and show incoming video
  currentCall.sendVideo(true);
  voxAPI.showLocalVideo(true);

  currentCall.unmuteMicrophone();
  // currentCall.showRemoteVideo(true);
}

// Call disconnected
function onCallDisconnected(e) {
  console.log("onCallDisconnected", e);
  currentCall = null;
}

// Call failed
function onCallFailed(e) {
  console.log("onCallFailed", e);
  // Error code -  e.code, error reason - e.reason
}
