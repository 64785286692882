import { useCallback, useContext, useEffect, useRef, useState } from "react";

import GlobalContext from "@/contexts/Global/GlobalContext";

import { PAYMENT_COUNTDOWN_GAP_MS } from "./constants";

/**
 * Receives backend countdown value and returns proxy countdown value to avoid excessive timer "jumping"
 * @returns
 */
const useCountdownTimestampProxy = () => {
  const { isExpert } = useContext(GlobalContext);
  const [realTimestamp, setRealTimestamp] = useState<number>();
  const [proxiedCountdownTimestamp, setProxiedCountdownTimerValue] = useState<number>();
  const timerUpdateFlag = useRef(true);

  const setTimestamp = useCallback((outOfMoneyTime: number) => {
    setRealTimestamp(outOfMoneyTime);
  }, [setRealTimestamp]);

  useEffect(() => {
    if (isExpert) {
      const expertCountdownTimer = setInterval(() => {
        timerUpdateFlag.current = true;
      }, 60000);
      return () =>
        clearInterval(expertCountdownTimer);
    }
  }, [isExpert]);

  useEffect(() => {
    if (!isExpert && realTimestamp && proxiedCountdownTimestamp === undefined) {
      setProxiedCountdownTimerValue(realTimestamp);
    }
  }, [isExpert, proxiedCountdownTimestamp, realTimestamp]);

  // Countdown timer set
  useEffect(() => {
    if (realTimestamp) {
      if (isExpert) {
        if (
          timerUpdateFlag.current
          || Math.ceil((realTimestamp - (proxiedCountdownTimestamp ?? 0)) / 1000) > PAYMENT_COUNTDOWN_GAP_MS
        ) {
          setProxiedCountdownTimerValue(realTimestamp);
          timerUpdateFlag.current = false;
        }
      } else if (!isExpert
          && Math.ceil((realTimestamp - (proxiedCountdownTimestamp ?? 0)) / 1000) > PAYMENT_COUNTDOWN_GAP_MS) {
        setProxiedCountdownTimerValue(realTimestamp);
      }
    }
  }, [isExpert, proxiedCountdownTimestamp, realTimestamp]);

  return {
    proxiedCountdownTimestamp,
    setTimestamp,
  };
};

export default useCountdownTimestampProxy;
