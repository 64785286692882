import React from "react";

import { getSession_getSession_client, getSession_getSession_expert } from "@/components/chat/graphql/__generated__/getSession";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import { inlineSkills } from "@/components/common/sessionWaitingBlock/utils";
import { UserAvatar } from "@/components/common/userAvatar";
import { UserAvatarTypeEnum } from "@/components/common/userAvatar/types";

import TextChatDuration from "../textChatDuration";
import { formatDateOfBirth } from "../utils";

import { TextChatHeaderProps } from "./types";
import "./styles.scss";

const TextChatHeader = ({
  endSessionCallback, outOfMoneyTime, sessionEndsSoonCallback, receiver, isExpert,
}: TextChatHeaderProps) =>
  (
    <div className="text-chat-header__top">
      <div className="text-chat-header__top-content">
        <div className="text-chat-header__top-content-block text-chat-header__top-content-block--left">
          <Button
            text="Закончить"
            onClick={endSessionCallback}
            size={ButtonSizeEnum.Small}
            color={ButtonColorEnum.Red}
          />
          <TextChatDuration timestamp={outOfMoneyTime} sessionEndsSoonCallback={sessionEndsSoonCallback} />
        </div>
        <div className="text-chat-header__top-content-block text-chat-header__companion">
          <>
            <div className="text-chat-header__companion-data">
              <div className="text-chat-header__companion-name">{receiver.name}</div>
              {!isExpert
                      && <div className="text-chat-header__companion-info">{inlineSkills((receiver as getSession_getSession_expert).skills ?? [])}</div>}
              {!!isExpert && <div className="text-chat-header__companion-info">{formatDateOfBirth((receiver as getSession_getSession_client).dateOfBirth)}</div>}
            </div>
            <div className="text-chat-header__companion-avatar">
              <UserAvatar
                mobileImage={receiver.mobileImage}
                desktopImage={receiver.desktopImage}
                name={receiver.name}
                type={UserAvatarTypeEnum.Chat}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );

export default TextChatHeader;
