import moment from "moment";

import { Message } from "./types";

export const convertToDisplayMessage = (messageEvent: any): Message =>
  ({
    uuid: messageEvent.message.uuid,
    sender: messageEvent.message.sender,
    text: messageEvent.message.text,
    payload: messageEvent.message.payload,
    timestamp: messageEvent.timestamp,
  });

export const formatDateOfBirth = (dateOfBirth: string) =>
  (dateOfBirth
    ? moment(dateOfBirth)
      .format("DD.MM.YYYY")
    : "");

export const calculateTimerValue = (timestamp: number) =>
  Math.ceil((new Date().getTime() - timestamp) / 1000);
