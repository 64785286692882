import React, { useCallback, useEffect, useRef, useState } from "react";

import { secondsToTime } from "@/utils/numberUtils";

import { TimerProps } from "./types";

/**
 * Component for counting seconds
 * @param value Timer value in seconds
 * @param isPaused
 * @param valueCallback
 * @return
 */
const TimerFixed = ({ value, isPaused = false, valueCallback }: TimerProps) => {
  const [currentTimeValue, setCurrentTimeValue] = useState<number>(value);
  const sessionDurationTimer = useRef<NodeJS.Timer | null>(null);

  const clearTimer = () => {
    if (sessionDurationTimer.current !== null) {
      clearInterval(sessionDurationTimer.current);
      sessionDurationTimer.current = null;
    }
  };

  const startTimer = useCallback(() => {
    if (sessionDurationTimer.current === null) {
      sessionDurationTimer.current = setInterval(() => {
        setCurrentTimeValue((oldValue) => {
          if (oldValue === -1) {
            clearTimer();
          }
          return oldValue + 1;
        });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    clearTimer();
    startTimer();
    setCurrentTimeValue(value);
  }, [startTimer, value]);

  useEffect(() => {
    if (isPaused) {
      clearTimer();
    } else {
      startTimer();
    }
  }, [isPaused, startTimer]);

  useEffect(() => {
    if (valueCallback) {
      valueCallback(currentTimeValue);
    }
  }, [currentTimeValue, valueCallback]);

  useEffect(
    () =>
      () => {
        clearTimer();
      },
    [],
  );

  return <>{currentTimeValue < 0 ? secondsToTime(Math.abs(currentTimeValue)) : "00:00"}</>;
};

export default TimerFixed;
