import { gql } from "@apollo/client";

export const CREATE_CONVERSATION = gql`
  mutation createConversation(
    $sessionID: Int!,
    $voxConversationID: String!
  ) {
    createConversation(
      sessionID: $sessionID,
      voxConversationID: $voxConversationID
    )
  }
`;
